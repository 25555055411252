import React, { useState, useEffect } from 'react';
import { Table, Modal, message, InputNumber, Button, Switch,  Tooltip } from 'antd';
import api from '../functions/api'; // Assuming this is a pre-configured Axios instance
import { CopyOutlined } from '@ant-design/icons'; // Import Ant Design's copy icon



const ScriptReporting = ({ isVisible, onClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  const [showRedAlertsOnly, setShowRedAlertsOnly] = useState(false);
  const countExceededInterval = () => data.filter(isRedAlert).length;
  const [scriptData, setScriptData] = useState({}); // Add state for script_data
  const isEditing = (record) => record.ID === editingKey;
  const [logFile, setLogFile] = useState(''); // State for log file
  const [missingScripts, setMissingScripts] = useState([]); // State for missing_in_db
  const [showMissingScripts, setShowMissingScripts] = useState(false); // Toggle visibility


  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success('Copied to clipboard!');
      })
      .catch(() => {
        message.error('Failed to copy!');
      });
  };

  const parseCronInterval = (cron) => {
    if (!cron) return null;
    const parts = cron.split(' ');
    if (parts.length < 5) return null;
  
    const [minute, hour, day, month, dayOfWeek] = parts;
  
    // Daily interval
    if (minute !== '*' && hour !== '*' && day === '*' && month === '*' && dayOfWeek === '*') {
      return 24; // 24 hours
    }
  
    // Weekly interval (runs on a specific day of the week)
    if (dayOfWeek !== '*' && day === '*' && month === '*') {
      return 7 * 24; // 7 days * 24 hours = 168 hours
    }
  
    // Default: return null if not recognized
    return null;
  };
  
  
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await api.get('/script_reporting');
      console.log("Response from script reporting", response);
  
      const { database_results, cron_sync_results, log_files, script_data } = response.data;
  
      setData(database_results); // Set table data
      setScriptData(script_data); // Store script_data for cron schedule lookup
      setLogFile(log_files.length > 0 ? log_files[0] : ''); // Store the first log file
      setMissingScripts(cron_sync_results.missing_in_db || []); // Store missing scripts
      console.log("Missing Scripts:", cron_sync_results.missing_in_db);
  
    } catch (error) {
      message.error('Failed to fetch script reporting data.');
      console.error('API error:', error);
    } finally {
      setLoading(false);
    }
  };
  
  
  

  useEffect(() => {
    if (isVisible) {
      fetchData(); // Fetch data whenever the modal becomes visible
    }
  }, [isVisible]);

  const edit = (record) => {
    setEditingKey(record.ID);
  };

  const save = async (id, newInterval) => {
    try {
      await api.put('/script_reporting', { ID: id, interval: newInterval });
      message.success('Interval updated successfully!');
      fetchData(); // Refresh data after update
    } catch (error) {
      message.error('Failed to update interval.');
      console.error('API error:', error);
    } finally {
      setEditingKey('');
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const getRowStyle = (record) => {
    const currentTime = Date.now();
    const recordTime = record.epoch * 1000; // Convert epoch to milliseconds
    const intervalInMillis = record.interval * 60 * 60 * 1000; // Convert interval to milliseconds

    if (currentTime - recordTime > intervalInMillis) {
      return {
        border: '1px solid red',
        padding: '4px',
        borderRadius: '4px',
      };
    }

    return {};
  };

  const isRedAlert = (record) => {
    const currentTime = Date.now();
    const recordTime = record.epoch * 1000; // Convert epoch to milliseconds
    const intervalInMillis = record.interval * 60 * 60 * 1000; // Convert interval to milliseconds
    return currentTime - recordTime > intervalInMillis;
  };

  const removePrefixAndSuffix = (scriptName) => {
    return scriptName.replace(/^(DR_|DC_|DS_)/, '').replace(/\.py$/, '');
  };

  const filteredData = showRedAlertsOnly ? data.filter(isRedAlert) : data;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
      sorter: (a, b) => a.ID - b.ID,
      render: (text) => (
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>{text}</span>
      ),
    },
    {
      title: 'Script Name',
      dataIndex: 'script_name',
      key: 'script_name',
      sorter: (a, b) => a.script_name.localeCompare(b.script_name),
      render: (_, record) => {
        const copyToClipboard = (text) => {
          navigator.clipboard
            .writeText(text)
            .then(() => {
              message.success('Copied to clipboard!');
            })
            .catch(() => {
              message.error('Failed to copy!');
            });
        };
    
        const isRed = isRedAlert(record);
    
        return (
          <div>
            <span
              style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '10px',
                fontWeight: isRed ? 'bold' : 'normal',
                color: isRed ? 'red' : 'inherit',
              }}
            >
              {record.script_name}
            </span>
            <CopyOutlined
              onClick={() => copyToClipboard(record.script_name)}
              style={{
                marginLeft: 8,
                cursor: 'pointer',
                color: '#1890ff', // Optional: color to make it stand out
              }}
              title="Copy to clipboard"
            />
          </div>
        );
      },
    },
    
    
    {
      title: 'Name',
      key: 'name',
      sorter: (a, b) => {
        const nameA = removePrefixAndSuffix(a.script_name).toLowerCase();
        const nameB = removePrefixAndSuffix(b.script_name).toLowerCase();
        return nameA.localeCompare(nameB);
      },
      render: (_, record) => (
        <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>
          {removePrefixAndSuffix(record.script_name)}
        </div>
      ),
    },
    {
      title: 'Cron Schedule',
      key: 'cron_schedule',
      sorter: (a, b) => {
        const cronA = scriptData[a.script_name] || '';
        const cronB = scriptData[b.script_name] || '';
        return cronA.localeCompare(cronB);
      },
      render: (_, record) => {
        const cronSchedule = scriptData[record.script_name] || '-';
        return (
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>
            {cronSchedule}
          </div>
        );
      },
    },
    
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      sorter: (a, b) => a.interval - b.interval,
      editable: true,
      render: (_, record) => {
        const editable = isEditing(record);
        const cronSchedule = scriptData[record.script_name] || '';
        const expectedInterval = parseCronInterval(cronSchedule);
        const isMismatch = expectedInterval && record.interval !== expectedInterval;
    
        return editable ? (
          <InputNumber
            min={0}
            defaultValue={record.interval}
            onBlur={(e) => save(record.ID, e.target.value)}
            style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}
          />
        ) : (
          <span
            onClick={() => edit(record)}
            style={{
              cursor: 'pointer',
              color: isMismatch ? 'red' : 'blue',
              fontFamily: 'Arial, sans-serif',
              fontSize: '10px',
            }}
          >
            {record.interval || '-'}
            {isMismatch && <span style={{ color: 'red', marginLeft: '4px' }}>?</span>}
          </span>
        );
      },
    },
    
    {
      title: 'Duration (minutes)',
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a.duration - b.duration,
      render: (text) => (
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>{text}</span>
      ),
    },
    {
      title: 'Row Report',
      dataIndex: 'row_report',
      key: 'row_report',
      sorter: (a, b) => a.row_report - b.row_report,
      render: (text) => (
        <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '10px' }}>{text}</span>
      ),
    },
    {
      title: 'Essential Results',
      dataIndex: 'essential_results',
      key: 'essential_results',
      sorter: (a, b) => (a.essential_results || '').localeCompare(b.essential_results || ''),
      render: (text) => {
        if (text) {
          return (
            <Tooltip title={text}>
              <span
                style={{
                  color: '#1890ff',
                  textDecoration: 'underline',
                  cursor: 'pointer',
                  fontFamily: 'Arial, sans-serif',
                  fontSize: '10px',
                }}
              >
                Tooltip
              </span>
            </Tooltip>
          );
        }
        return (
          <span
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '10px',
            }}
          >
            -
          </span>
        );
      },
    },
    {
      title: 'Epoch',
      dataIndex: 'epoch',
      key: 'epoch',
      sorter: (a, b) => a.epoch - b.epoch,
      render: (text) => (
        <span
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '10px',
            whiteSpace: 'nowrap', // Ensures data stays in one line
          }}
        >
          {new Date(text * 1000).toLocaleString()}
        </span>
      ),
    },
  ];

  return (
    <Modal
  title={
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>Script Reporting ({countExceededInterval()} of scripts exceeded interval)</span>
        <div>
          <Switch
            checked={showRedAlertsOnly}
            onChange={setShowRedAlertsOnly}
            checkedChildren="Red Alerts On"
            unCheckedChildren="Red Alerts Off"
          />
        </div>
      </div>
      {/* Log File Section */}
      {logFile && (
        <div style={{ marginTop: '8px', display: 'flex', alignItems: 'center' }}>
          <span style={{ fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
            Logfile: {logFile}
          </span>
          <CopyOutlined
            onClick={() => copyToClipboard(logFile)}
            style={{
              marginLeft: '8px',
              cursor: 'pointer',
              color: '#1890ff',
            }}
            title="Copy log file path"
          />
        </div>
      )}
      {/* Missing Scripts Section */}
      {missingScripts.length > 0 && (
        <div style={{ marginTop: '8px', fontFamily: 'Arial, sans-serif', fontSize: '12px' }}>
          <span
            onClick={() => setShowMissingScripts(!showMissingScripts)}
            style={{
              color: '#1890ff',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
          >
            Scripts not in reporting database
          </span>
          {showMissingScripts && (
            <ul style={{ marginTop: '4px', paddingLeft: '20px' }}>
              {missingScripts.map((script, index) => (
                <li key={index} style={{ fontSize: '10px' }}>{script}</li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  }
  visible={isVisible}
  onCancel={onClose}
  footer={[
    <Button key="refresh" onClick={fetchData} loading={loading}>
      Refresh
    </Button>,
    <Button key="close" onClick={onClose}>
      Close
    </Button>,
  ]}
  width={1500}
>
  <Table
    dataSource={filteredData}
    columns={columns}
    rowKey="ID"
    loading={loading}
    pagination={{ pageSize: 20 }}
    rowClassName={() => 'table-low-row'}
  />
</Modal>

  );
};

export default ScriptReporting;
