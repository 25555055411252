import React, { useState, useEffect } from 'react';
import { Table, Button, Switch, AutoComplete, Input } from 'antd';
//
const MaintenanceDP = ({ DP_dataset, onSave, isEsgOnly, setIsEsgOnly }) => {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [editingKey, setEditingKey] = useState({});
  const [editingValue, setEditingValue] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState(DP_dataset);
  const [selectedSearchItem, setSelectedSearchItem] = useState(null);

  // Sync filteredData with DP_dataset whenever DP_dataset changes
useEffect(() => {
  setFilteredData(DP_dataset);
}, [DP_dataset]);


  // Search options based on 'DataField' column
  const searchOptions = DP_dataset.map(item => ({
    value: item.DataField,
    label: item.DataField,
    key: item.ID,
  }));

  const handleSearch = (value) => {
    setSearchValue(value);
    if (value) {
      const filtered = DP_dataset.filter(item =>
        item.DataField.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(DP_dataset);
    }
  };

  const handleSelectSearchItem = (value) => {
    setSelectedSearchItem(value);
    const filtered = DP_dataset.filter(item => item.DataField === value);
    setFilteredData(filtered);
  };

  const generateColumns = () => {
    const columnOrder = [
      "ID", "DataField", "snapshot", "source", "show_infront", "SourceTable", "Choosable", "Choosable_alerts",
      "ExternalLink", "Tooltip", "Label", "Data_delta", "Searchable", "Negative", "Positive", "Values",
      "Category", "Green", "Lightgreen", "Yellow", "Orange", "Red", "in_esg_table", "stockinfo_tabletitle",
      "esg_summary_order", "esg_detail_order", "periodic_change" 
    ];

    const filteredColumns = columnOrder.filter(key => {
      if (isEsgOnly) {
        return key === "ID" || key === "DataField" || key.toLowerCase().includes("esg") || key === "stockinfo_tabletitle" || key === "Values" || key === "Tooltip";
      }
      return true;
    });

    return filteredColumns.map(key => ({
      title: key,
      dataIndex: key,
      key,
      sorter: (a, b) => {
        if (typeof a[key] === 'number') return a[key] - b[key];
        if (typeof a[key] === 'string') return a[key]?.localeCompare(b[key]);
        return 0;
      },
      render: (text, record) => {
        const isEditing = record.ID === editingKey.rowId && key === editingKey.columnKey;
        return isEditing ? (
          <div>
            <input
              value={editingValue}
              onChange={(e) => setEditingValue(e.target.value)}
              style={{ marginBottom: '10px', fontSize: '12px' }}
            />
            <Button
              type="primary"
              onClick={() => {
                onSave(record.ID, key, editingValue);
                setEditingKey({});
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setEditingKey({ rowId: record.ID, columnKey: key });
              setEditingValue(text || '');
            }}
          >
            {text || "Click to add value"}
          </div>
        );
      },
    }));
  };

  return (
    <div>
      <Button onClick={() => setIsTableVisible(!isTableVisible)}>
        {isTableVisible ? 'Collapse DP Table' : 'Expand DP Table'}
      </Button>

      {isTableVisible && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
            <Switch
              checkedChildren="ESG Only"
              unCheckedChildren="All"
              checked={isEsgOnly}
              onChange={setIsEsgOnly}
              style={{ marginRight: '10px' }}
            />
            <AutoComplete
              options={searchOptions}
              style={{ width: 300 }}
              onSearch={handleSearch}
              onSelect={handleSelectSearchItem}
              placeholder="Search DataField..."
            >
              <Input.Search />
            </AutoComplete>
          </div>
          <Table
            className="custom-table"
            size="small"
            dataSource={isEsgOnly
              ? filteredData.filter(item => item.Category === "ESG")
              : filteredData}
            columns={generateColumns()}
            rowKey="ID"
          />
        </>
      )}
    </div>
  );
};

export default MaintenanceDP;
