///MaintenanceModal.jsx

import { useState, useEffect } from 'react';
import { Modal, Table, Button, Switch, Tabs, Input } from 'antd';
import api from '../functions/api';
import TickerMaintenance from './TickerMaintenance'
import { toggleDemoMode, toggleDebugMode } from '../redux/actions';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Maintain_RatingAgencies from './Maintain_RatingAgencies';
import store from '../redux/store';
import { getDataParameters } from '../redux/reducers/portfolioReducer'; // Import your thunk action
import ScriptsComponent from './ScriptsComponent'; // Import your new component
import MaintenanceDP from './Maintenance_DP';


const MaintenanceModal = ({ isVisible, setIsVisible }) => {
  const [isEsgOnly, setIsEsgOnly] = useState(false); // State for ESG Only switch

  const dispatch = useDispatch();
  const DP_dataset = useSelector(state => state.portfolio?.parametersData?.DP_dataset_json);
  useEffect(() => {
    if (!DP_dataset || DP_dataset.length === 0) {
      dispatch(getDataParameters());
    }
  }, [dispatch, DP_dataset]);

  const isDebugMode = useSelector(state => state.debugMode?.isDebugMode);
  // Log the entire state only when debugMode.isDebugMode is true
  // Log the entire state only when isDebugMode is true
  useEffect(() => {
    if (isDebugMode) {
      // Use store.getState() to access the full state when debug mode is on
      const allState = store.getState();  // Assuming 'store' is imported or accessible in this file
      console.log("All State: ", allState);
      console.log("isDebugMode: ", isDebugMode);
    }
  }, [isDebugMode]);  // Effect runs only when isDebugMode changes

  const VDP_dataset = useSelector(state => state.portfolio?.parametersData?.VDP_dataset_json);
  // console.log('DP_dataset from Redux store:', DP_dataset);

  
  const [isScriptsVisible, setIsScriptsVisible] = useState(false); // New state for scripts section visibility
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [switchState, setSwitchState] = useState(true);
  const [vdpDataset, setVdpDataset] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [shouldCallDataParametersChange, setShouldCallDataParametersChange] = useState(false);
  const [shouldCallVectorDataParametersChange, setShouldCallVectorDataParametersChange] = useState(false);
  const [changedData, setChangedData] = useState(null);
  const [activeDatasetType, setActiveDatasetType] = useState('dp'); // New state to track the active dataset type
  const [isTickerMaintenanceVisible, setIsTickerMaintenanceVisible] = useState(false);
  const [isAlertMaintenanceVisible, setIsAlertMaintenanceVisible] = useState(false);
  const [isAlertClauseDefinitionVisible, setIsAlertClauseDefinitionVisible] = useState(false);
  const [isConfigureAlertVisible, setIsConfigureAlertVisible] = useState(false);
  const [esg_tables_data_coverage, setEsg_tables_data_coverage] = useState(false);
  const [isEsgRatingAgenciesVisible, setIsEsgRatingAgenciesVisible] = useState(false);
  const esgRatingAgenciesDataset = useSelector(state => state.portfolio?.parametersData?.esg_rating_agencies_dataset_json);

  const esgRatingAgenciesColumns = esgRatingAgenciesDataset && esgRatingAgenciesDataset.length > 0
  ? Object.keys(esgRatingAgenciesDataset[0]).map(key => ({
      title: key.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase()), // Convert to title case
      dataIndex: key,
      key: key,
  }))
  : [];



    // Existing state for table visibility
  const [isTableVisible, setIsTableVisible] = useState({
    msci: false,
    refinitiv: false,
    sa: false,
    dp: false,
    vdp: false,
  });


  // const dispatch = useDispatch();
  const demoState = useSelector(state => state.demoMode?.isDemoMode);
  const debugState = useSelector(state => state.debugMode?.isDebugMode);

  // console.log("debugState ", debugState)

  const [isAssessmentTableVisible, setIsAssessmentTableVisible] = useState(false);
  const [assessmentData, setAssessmentData] = useState([]);

  const fetchAssessmentData = () => {
    setIsFetchingData(true);
    api.get('/epoch_assessment_results')
      .then(response => {
        // console.log("Raw assessment data", response.data);
        const processedData = response.data.map(item => ({
          ...item,
          percentage_without_epoch: parseFloat(item.percentage_without_epoch),
          percentage_less_than_48_hours: parseFloat(item.percentage_less_than_48_hours),
          percentage_between_48_hours_and_1_week: parseFloat(item.percentage_between_48_hours_and_1_week),
          percentage_between_1_week_and_1_month: parseFloat(item.percentage_between_1_week_and_1_month),
          percentage_more_than_1_month: parseFloat(item.percentage_more_than_1_month)
        }));
        // console.log("Processed assessment data", processedData);
        setAssessmentData(processedData);
      })
      .catch(error => {
        console.error('Error fetching assessment data:', error);
      })
      .finally(() => {
        setIsFetchingData(false);
      });
  };
  
  const handleToggleDemo = () => {
    dispatch(toggleDemoMode(!demoState));
  };

  const handleToggleDebug = () => {
    dispatch(toggleDebugMode(!debugState));
  };


   useEffect(() => {
    if (isVisible) {
      fetchData();
    }
  }, [isVisible]);
  

  const fetchData = () => {
    setIsFetchingData(true);  // Set fetching state to true before fetching data
    console.log("FETCHING MAINTENANCES")
    api.post('/maintenance')
      .then(response => {
        // console.log('Maintenance API response:', response.data);

        const { msci_dataset_json, refinitiv_dataset_json, sa_dataset_json, esg_tables_data_coverage } = response.data;
        setData({ msciData: msci_dataset_json, refinitivData: refinitiv_dataset_json, saData: sa_dataset_json });
        setEsg_tables_data_coverage(esg_tables_data_coverage);
      })
      .catch(error => {
        console.error('Maintenance API error:', error);
      })
      .finally(() => {
        setIsFetchingData(false);  // Reset fetching state to false after fetching data
      });
  };
  
// Use an object to keep track of both the row ID and the column key of the cell being edited
  const [editingKey, setEditingKey] = useState({});
//   const [editingKey, setEditingKey] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  
  const generateColumns = () => {
    const columnOrder = [
      "ID", "DataField", "snapshot", "source", "show_infront", "SourceTable", "Choosable", "Choosable_alerts", "ExternalLink",
      "Tooltip", "Label", "Data_delta", "Searchable", "Negative", "Positive", "Values", "Category",
      "Green", "Lightgreen", "Yellow", "Orange", "Red", "in_esg_table", "stockinfo_tabletitle",
      "esg_summary_order", "esg_detail_order",  "periodic_change"
    ];

  };
  
  const handleSave = (id, key, value) => {
    console.log('Save data:', id, key, value);
  
    // Create the changedData object with the modified values
    let changedData = {
      id: id,  // the ID of the changed row
      key: key,  // the key/name of the changed column
      value: value,  // the new value of the changed cell
    };
  
    // Set the state with the changedData object
    setChangedData(changedData);
  
    // Set flag for calling the correct API based on activeDatasetType
    setShouldCallDataParametersChange(activeDatasetType === 'dp');
    setShouldCallVectorDataParametersChange(activeDatasetType === 'vdp');
  
    // Find and update the value in the correct dataset based on the active tab
    switch (activeTab) {
      case '1':
        // Update msciData locally without fetching from backend
        setData(prevData => ({
          ...prevData,
          msciData: prevData.msciData.map(item => (item.ID === id ? { ...item, [key]: value } : item))
        }));
        break;
      case '2':
        // Update refinitivData locally without fetching from backend
        setData(prevData => ({
          ...prevData,
          refinitivData: prevData.refinitivData.map(item => (item.ID === id ? { ...item, [key]: value } : item))
        }));
        break;
      case '3':
        // Update saData locally without fetching from backend
        setData(prevData => ({
          ...prevData,
          saData: prevData.saData.map(item => (item.ID === id ? { ...item, [key]: value } : item))
        }));
        break;
      
      case 'vdp':
        // Update vdpDataset locally if the active tab is 'vdp'
        setVdpDataset(prevData =>
          prevData.map(item => (item.ID === id ? { ...item, [key]: value } : item))
        );
        break;
      default:
        break;
    }
  
    // No need to refetch the entire dataset immediately unless you want to ensure backend sync
    // Remove the fetchData() call unless you need to ensure that the server's data is reflected immediately
    // fetchData(); // Optionally refetch the data to ensure synchronization with backend
  };
  

  const [activeTab, setActiveTab] = useState('1');

  const [data, setData] = useState({ msciData: [], refinitivData: [], saData: [] });

  const handleRefresh = () => {
    let source;
    switch (activeTab) {
      case '1':
        source = 'MSCI';
        break;
      case '2':
        source = 'Refinitiv';
        break;
      case '3':
        source = 'Sustainanalytics';
        break;
      default:
        source = 'unknown'; // default source value
    }
  
    api.post('/ESG_link_approve', {
      switchState,
      tickers: selectedRowKeys,
      source,  // Pass the source parameter based on the active tab
    })
    .then(response => {
      console.log('ESG link approve API response:', response.data);
      fetchData();  // Refetch data after approving/rejecting links
    })
    .catch(error => {
      console.error('ESG link approve API error:', error);
    });
  };
  

useEffect(() => {
    if (!isFetchingData) {
      if (shouldCallDataParametersChange) {
        api.post('/Data_Parameters_change', { data: [changedData] })
          .then(handleApiResponse)
          .catch(handleApiError)
          .finally(() => resetApiCallStates());
      } else if (shouldCallVectorDataParametersChange) {
        api.post('/Vector_Data_Parameters_change', { data: [changedData] })
          .then(handleApiResponse)
          .catch(handleApiError)
          .finally(() => resetApiCallStates());
      }
    }
  }, [changedData, shouldCallDataParametersChange, shouldCallVectorDataParametersChange, isFetchingData]);
 
  // Helper functions for API response, error handling, and state reset
const handleApiResponse = response => {
  console.log('API response:', response.data);
  dispatch(getDataParameters());
  fetchData(); // Refetch data
};

const handleApiError = error => {
  console.error('API error:', error);
};

const resetApiCallStates = () => {
  setShouldCallDataParametersChange(false);
  setShouldCallVectorDataParametersChange(false); // Reset VDP call state
  setChangedData(null); // Clear changed data
};
  
  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const msciColumns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'MSCI Link',
      dataIndex: 'MSCI_link',
      render: (text) => <a href={text}>{text}</a>,
    },
  ];

  const refinitivColumns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'Refinitiv Link',
      dataIndex: 'Refinitiv_link',
      render: (text) => <a href={text}>{text}</a>,
    },
  ];

  const saColumns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
    },
    {
      title: 'Company',
      dataIndex: 'company',
    },
    {
      title: 'Sustainanalytics Link',
      dataIndex: 'Sustainanalytics_link',
      render: (text) => <a href={text}>{text}</a>,
    },
  ];

  // Function to render dynamic content for each dataset
const renderDatasetInfo = (dataset) => {
  return Object.keys(dataset).map(section => (
    <div key={section} style={{ marginBottom: '10px' }}>
      <strong>{section.replace('_', ' ').toUpperCase()}:</strong>
      <div style={{ marginLeft: '15px' }}>
        {Object.keys(dataset[section]).map(field => (
          <div key={field}>
            {field.replace('_', ' ').charAt(0).toUpperCase() + field.replace('_', ' ').slice(1)}: 
            {' '}
            {typeof dataset[section][field] === 'number'
              ? dataset[section][field].toFixed(2)
              : dataset[section][field]}
            {typeof dataset[section][field] === 'object' && dataset[section][field] !== null
              ? renderDatasetInfo(dataset[section][field])
              : null}
          </div>
        ))}
      </div>
    </div>
  ));
};

  return (
    <Modal
    
      title={
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          Maintenance

          <div>
            <Switch 
              checkedChildren="Regular mode" 
              unCheckedChildren="Demo mode" 
              checked={demoState} 
              onChange={handleToggleDemo}
            />
            <Switch 
              checkedChildren="Regular mode" 
              unCheckedChildren="Debugmode" 
              checked={debugState} 
              onChange={handleToggleDebug}
            />
            
          </div>


          <div>
            <Switch 
              checkedChildren="Approve" 
              unCheckedChildren="Reject" 
              style={{ marginRight: '16px' }} 
              checked={switchState} 
              onChange={setSwitchState}
            />
            <Button type="primary" onClick={handleRefresh} disabled={selectedRowKeys.length === 0}>
              Refresh
            </Button>
          </div>
        </div>
      }
      open={isVisible}
      onCancel={() => setIsVisible(false)}
      width={3000}
      footer={[
        <Button key="ok" type="primary" onClick={() => setIsVisible(false)}>
          OK
        </Button>,
  ]} 
    >
    <Tabs onChange={(activeKey) => setActiveTab(activeKey)}>

    <Tabs.TabPane tab="MSCI" key="1">
  <Button onClick={() => setIsTableVisible(prev => ({ ...prev, msci: !prev.msci }))}>
    {isTableVisible.msci ? 'Collapse' : 'Expand'}
  </Button>
  {isTableVisible.msci && (
    <>
      <Table
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        dataSource={data.msciData}
        columns={msciColumns}
        rowKey="ticker"
      />
      <Button onClick={() => setIsTableVisible(prev => ({ ...prev, msciInfo: !prev.msciInfo }))} style={{ marginTop: '10px' }}>
        {isTableVisible.msciInfo ? 'Collapse Datacoverage ' : 'Expand Datacoverage'}
      </Button>
      {isTableVisible.msciInfo && (
        <div style={{ fontSize: '12px', marginBottom: '10px' }}>
          {renderDatasetInfo(esg_tables_data_coverage.MSCI_Data)}
        </div>
      )}
    </>
  )}
</Tabs.TabPane>


<Tabs.TabPane tab="Refinitiv" key="2">
  <Button onClick={() => setIsTableVisible(prev => ({ ...prev, refinitiv: !prev.refinitiv }))}>
    {isTableVisible.refinitiv ? 'Collapse' : 'Expand'}
  </Button>
  {isTableVisible.refinitiv && (
    <>
      <Table
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        dataSource={data.refinitivData}
        columns={refinitivColumns}
        rowKey="ticker"
      />
      <Button onClick={() => setIsTableVisible(prev => ({ ...prev, refinitivInfo: !prev.refinitivInfo }))} style={{ marginTop: '10px' }}>
        {isTableVisible.refinitivInfo ? 'Collapse Datacoverage' : 'Expand Datacoverage'}
      </Button>
      {isTableVisible.refinitivInfo && (
        <div style={{ fontSize: '12px', marginBottom: '10px' }}>
          {renderDatasetInfo(esg_tables_data_coverage.Refinitiv_Data)}
        </div>
      )}
    </>
  )}
</Tabs.TabPane>


<Tabs.TabPane tab="Sustainanalytics" key="3">
  <Button onClick={() => setIsTableVisible(prev => ({ ...prev, sa: !prev.sa }))}>
    {isTableVisible.sa ? 'Collapse' : 'Expand'}
  </Button>
  {isTableVisible.sa && (
    <>
      <Table
        size="small"
        rowSelection={{
          selectedRowKeys,
          onChange: onSelectChange,
        }}
        dataSource={data.saData}
        columns={saColumns}
        rowKey="ticker"
      />
      <Button onClick={() => setIsTableVisible(prev => ({ ...prev, saInfo: !prev.saInfo }))} style={{ marginTop: '10px' }}>
        {isTableVisible.saInfo ? 'Collapse Datacoverage' : 'Expand Datacoverage'}
      </Button>
      {isTableVisible.saInfo && (
        <div style={{ fontSize: '12px', marginBottom: '10px' }}>
          {renderDatasetInfo(esg_tables_data_coverage.Sustainanalytics_Data)}
        </div>
      )}
    </>
  )}
</Tabs.TabPane>

    </Tabs>

    <Button onClick={() => setIsEsgRatingAgenciesVisible(prev => !prev)}>
        {isEsgRatingAgenciesVisible ? 'Collapse ESG Rating Agencies' : 'Expand ESG Rating Agencies'}
      </Button>
      {isEsgRatingAgenciesVisible && <Maintain_RatingAgencies />}


    {/* // JSX for DP Table section */}

    <MaintenanceDP
      DP_dataset={DP_dataset}
      onSave={handleSave}
      isEsgOnly={isEsgOnly}
      setIsEsgOnly={setIsEsgOnly}
    />


{/* <Button
  onClick={() => {
    setIsTableVisible((prev) => ({ ...prev, dp: !prev.dp }));
    setActiveDatasetType('dp'); // Set active dataset type to dp
  }}
>
  {isTableVisible.dp ? 'Collapse DP Table' : 'Expand DP Table'}
</Button>

{isTableVisible.dp && (
  <>
    <Switch
      checkedChildren="ESG Only"
      unCheckedChildren="All"
      checked={isEsgOnly}
      onChange={setIsEsgOnly} // Toggle ESG Only state
      style={{ marginLeft: '10px', marginBottom: '10px' }} // Optional styling for spacing
    />
    <Table
      className="custom-table"
      size="small"
      dataSource={isEsgOnly ? DP_dataset.filter(item => item.Category === "ESG") : DP_dataset} // Filter rows where Category
      // dataSource={isEsgOnly ? dpDataset.filter(item => item.in_esg_table) : dpDataset} // Filter data based on ESG Only switch
      columns={generateColumns()}
      rowKey="ID"
    />
  </>
)} */}
    
    <Button onClick={() => {
    setIsTableVisible(prev => ({ ...prev, vdp: !prev.vdp }));
    setActiveDatasetType('vdp'); // Set active dataset type to vdp
  }}>
    {isTableVisible.vdp ? 'Collapse VDP Table' : 'Expand VDP Table'}
  </Button>
    {isTableVisible.vdp && (
        <Table 
            className="custom-table"
            size="small"
            dataSource={VDP_dataset} 
            columns={generateColumns(vdpDataset)} 
            rowKey="id" 
            />
    )}

      <Button onClick={() => setIsTickerMaintenanceVisible(prev => !prev)}>
        {isTickerMaintenanceVisible ? 'Collapse Ticker Maintenance' : 'Expand Ticker Maintenance'}
      </Button>
      {/* Conditionally render the TickerMaintenance component */}
      {isTickerMaintenanceVisible && <TickerMaintenance />}
        

      <Button onClick={() => {
  setIsAssessmentTableVisible(prev => !prev);
  if (!isAssessmentTableVisible) {
    fetchAssessmentData();
  }
}}>
  {isAssessmentTableVisible ? 'Collapse Table Assessment' : 'Expand Table Assessment'}
</Button>
{isAssessmentTableVisible && (
  <Table
    size="small"
    dataSource={assessmentData}
    columns={[
      { title: 'Table', dataIndex: 'table_name', key: 'table_name' },
      { title: 'Total Records', dataIndex: 'total_records', key: 'total_records' },
      { title: '% Without Epoch', dataIndex: 'percentage_without_epoch', key: 'percentage_without_epoch' },
      { title: '% Less than 48 hours old', dataIndex: 'percentage_less_than_48_hours', key: 'percentage_less_than_48_hours' },
      { title: '% Between 48 hours and 1 week', dataIndex: 'percentage_between_48_hours_and_1_week', key: 'percentage_between_48_hours_and_1_week' },
      { title: '% Between 1 week and 1 month', dataIndex: 'percentage_between_1_week_and_1_month', key: 'percentage_between_1_week_and_1_month' },
      { title: '% More than 1 month old', dataIndex: 'percentage_more_than_1_month', key: 'percentage_more_than_1_month' }
    ]}
    rowKey="id"
  />
)}

{/* New Scripts Button */}
<Button onClick={() => setIsScriptsVisible(prev => !prev)}>
  {isScriptsVisible ? 'Collapse Scripts' : 'Expand Scripts'}
</Button>

{isScriptsVisible && <ScriptsComponent />} {/* Conditionally render ScriptsComponent when the button is clicked */}

  </Modal>
  );
};

export default MaintenanceModal;
