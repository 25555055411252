//  Alerts.jsx
import React, { useState, useEffect } from 'react';
import { Modal, Button, Tabs, Badge } from 'antd';
import api from '../functions/api';
import getUserId from '../functions/getUserId';
// import PortfolioAlertsPane from '../OLD STUFF/PortfolioAlertsPane';
// import MotleyFoolAlertsPane from '../OLD STUFF/MotleyFoolAlertsPane'; // Make sure this component is created
import PF_Alerts from './PF_Alerts';  
import { useSelector } from 'react-redux';

const { TabPane } = Tabs;

const Alerts = ({ disabled }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [portfolioAlerts, setPortfolioAlerts] = useState([]);
  const [motleyFoolAlerts, setMotleyFoolAlerts] = useState([]);

  const showModal = () => {
    setIsVisible(true);
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const fetchPortfolioAlerts = async () => {
    const userId = getUserId();
    try {
      const response = await api.post('/get_alerts_archive', { userId });
      setPortfolioAlerts(response.data);
    } catch (error) {
      console.error('Error fetching portfolio alerts:', error);
    }
  };

  const fetchMotleyFoolAlerts = async () => {
    const userId = getUserId();
    try {
      const response = await api.post('/get_MF_data', { userId });
      console.log("MF data", response)
      setMotleyFoolAlerts(response.data);
    } catch (error) {
      console.error('Error fetching motley fool alerts:', error);
    }
  };

  useEffect(() => {
    if (isVisible) {
      fetchPortfolioAlerts();
      fetchMotleyFoolAlerts();
    }
  }, [isVisible]);

  const hasUnseenAlerts = useSelector(state =>
    state.portfolio?.data?.pf_alert?.some(alert => alert.seen === 0)
  );

  return (
    <>
       <Badge dot={hasUnseenAlerts} color="red">
      <Button type="primary" onClick={showModal} disabled={disabled}>
        Alerts
      </Button>
      </Badge>
      <Modal
        title="Alerts"
        visible={isVisible}
        onCancel={handleClose}
        width={1200}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>
            OK
          </Button>,
        ]}
      >
        <Tabs defaultActiveKey="1">
        <TabPane tab="Portfolio Alerts" key="1">
         <PF_Alerts /> 
          </TabPane>
          {/* <TabPane tab="Portfolio Alerts Archive" key="2">
            <PortfolioAlertsPane alerts={portfolioAlerts} />
          </TabPane>
          <TabPane tab="Motley Fool Alerts" key="3">
            <MotleyFoolAlertsPane alerts={motleyFoolAlerts} />
          </TabPane> */}
        </Tabs>
      </Modal>
    </>
  );
};

export default Alerts;
