// epochdate.js

/**
 * Converts an epoch integer value to a human-readable date string.
 * @param {number} epoch - The epoch time in seconds.
 * @returns {string} - The formatted date string in 'YYYY-MM-DD HH:mm:ss' format.
 */
const epochToDate = (epoch) => {
    if (!epoch || typeof epoch !== 'number') {
      throw new Error('Invalid epoch value. Please provide a valid number.');
    }
  
    const date = new Date(epoch * 1000); // Convert seconds to milliseconds
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  export default epochToDate;
  