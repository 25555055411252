import React, { useState, useEffect } from 'react';
import { Modal, Button, Spin, Alert, Table, Tooltip } from 'antd';
import api from '../functions/api';
import { useSelector } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';

const OpportunityCheck = ({ ticker, onClose }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [evaluatedTickerData, setEvaluatedTickerData] = useState([]);
  const [opportunitiesData, setOpportunitiesData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const DP_dataset_json = useSelector(state => state?.portfolio?.parametersData?.DP_dataset_json);
  const userId = getUserId();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);

  const tableStyles = {
    header: { 
      fontFamily: 'Arial, sans-serif', 
      fontSize: '14px', 
      lineHeight: '1.5', 
      fontWeight: 'bold', 
      textAlign: 'center', 
      verticalAlign: 'middle',
    },
    cell: { 
      fontFamily: 'Arial, sans-serif', 
      fontSize: '12px', 
      lineHeight: '1.4', 
      textAlign: 'center', 
      verticalAlign: 'middle',
    },
  };
  
  

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        setLoading(true);
        setError(null);

        // Call the API with the ticker
        const response = await api.post('/opportunity_check', { ticker });

        console.log("opportunities ", response)

        if (response.status === 200) {
          const data = response.data[0]; // Assuming data is an array with the first item being relevant
          if (data?.evaluated_ticker && Array.isArray(data.opportunities)) {
            const evaluatedRow = { ...data.evaluated_ticker, isEvaluated: true };
            setEvaluatedTickerData([evaluatedRow]);
            setOpportunitiesData(data.opportunities);

            // Dynamically create columns based on evaluated_ticker structure
            const dynamicColumns = Object.keys(data.evaluated_ticker || {}).map((key) => {
              const dpField = DP_dataset_json?.find((item) => item.DataField === key);
              const showBehind = dpField?.show_behind || '';
              const showInfront = dpField?.show_infront || '';
              const tooltip = dpField?.Tooltip || '';
            
              return {
                title: (
                  <Tooltip title={tooltip}>
                    <span style={tableStyles.header}>{key.replace(/_/g, ' ').toUpperCase()}</span>
                  </Tooltip>
                ),
                dataIndex: key,
                key,
                align: 'center', // Middle align all content for the column
                render: (value, record) => {
                  const displayValue =
                    value && !isNaN(value)
                      ? `${parseFloat(value).toFixed(2)}`
                      : value || 'N/A';
            
                  if (key === 'Ticker' && !record.isEvaluated) {
                    return (
                      <span
                        style={{ ...tableStyles.cell, color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() => {
                          setSelectedTicker(value);
                          setIsModalVisible(true);
                        }}
                      >
                        {`${showInfront}${displayValue}${showBehind}`}
                      </span>
                    );
                  }
            
                  return <span style={tableStyles.cell}>{`${showInfront}${displayValue}${showBehind}`}</span>;
                },
              };
            });
            
            
              

            setColumns(dynamicColumns);
          } else {
            setError("Unexpected response structure from API.");
          }
        } else {
          setError(response.data.error || 'Unknown error occurred.');
        }
      } catch (err) {
        setError(err.message || 'Failed to fetch opportunities.');
      } finally {
        setLoading(false);
      }
    };

    if (ticker) {
      fetchOpportunities();
    }
  }, [ticker, DP_dataset_json]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  console.log("opportunities ", opportunitiesData)

  return (
    <>
      <Modal
  title={`Found ${
    opportunitiesData.length > 0 ? opportunitiesData.length : 'No'
  } alternative investment opportunities for ${ticker}`}
  visible={true}
  onCancel={onClose}
  footer={[
    <Button key="cancel" onClick={onClose}>
      Cancel
    </Button>,
    <Button key="ok" type="primary" onClick={onClose}>
      OK
    </Button>,
  ]}
  width={1400}
>

        {loading && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <Spin tip="Loading opportunities..." />
          </div>
        )}
        {error && (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            style={{ marginBottom: '20px' }}
          />
        )}
        {!loading && !error && (
          <>
            {/* Table for Evaluated Ticker */}
            <h3>Evaluated Ticker</h3>
            <Table
                  dataSource={evaluatedTickerData}
                  columns={columns}
                  rowKey={(record) => record.Ticker || Math.random()}
                  pagination={false}
                  style={{ lineHeight: '1.4', textAlign: 'center' }} // Align rows to the center
                />

            {/* Table for Opportunities */}
            <h3>Opportunities</h3>
            <Table
                  dataSource={opportunitiesData}
                  columns={columns}
                  rowClassName={() => ''}
                  rowKey={(record) => record.Ticker || Math.random()}
                  pagination={{
                    ...pagination,
                    total: opportunitiesData.length,
                  }}
                  onChange={handleTableChange}
                  style={{ lineHeight: '1.4', textAlign: 'center' }} // Align rows to the center
                />
          </>
        )}
        {!loading && !error && opportunitiesData.length === 0 && (
          <Alert
            message="No Opportunities Found"
            description={`No better opportunities were found for ${ticker}.`}
            type="info"
            showIcon
          />
        )}
      </Modal>

      {isModalVisible && (
        <ThreeTablesModal
          tickerValue={selectedTicker}
          open={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          userId={userId}
        />
      )}
    </>
  );
};

export default OpportunityCheck;
