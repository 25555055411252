// AlertTable.jsx

import React from "react";
import { Table, Spin, Alert, Collapse, Button, Tooltip } from "antd";
import epochToDate from "../functions/epochdate";

const { Panel } = Collapse;

const AlertTable = ({
  alerts,
  alertsLoading,
  alertsError,
  tableTitle,
  onEditAlert,
  onDeleteAlert,
  alertEffectiveness
}) => {
  // Function to extract just the date (YYYY-MM-DD) from epochToDate output
  const formatDate = (epoch) => {
    const fullDate = epochToDate(epoch); // Get full date-time from the function
    return fullDate.split(" ")[0]; // Split by space and return only the date part
  };

  // console.log("alertEffectiveness in AlertTable ", alertEffectiveness)
  // console.log("alerts in AlertTable ", alerts)

  // Enrich alerts with corresponding alertEffectiveness data
const enrichedAlerts = alerts.map((alert) => {
  const effectivenessData = alertEffectiveness.find(
    (eff) => String(eff.alert_subtype_identifier) === String(alert.ID)
  );

  return {
    ...alert,
    n_total: effectivenessData ? effectivenessData.n_total : null, // Add n_total from effectivenessData
  };
});


  const alertColumns = [
    { title: "ID", dataIndex: "ID", key: "ID" },
    { title: "Scope", dataIndex: "scope", key: "scope" },
    { title: "Alert Type", dataIndex: "alert_type", key: "alert_type" },
    {
      title: "Advice Text",
      dataIndex: "advice_txt",
      key: "advice_txt",
      render: (text) => {
        // Regular expression to find text within <> brackets
        const parts = text.split(/(<[^>]+>)/g).map((part, index) =>
          part.startsWith("<") && part.endsWith(">")
            ? <span key={index} style={{ fontStyle: "italic" }}>{part}</span>
            : <span key={index} style={{ fontWeight: "bold" }}>{part}</span>
        );
        return <div>{parts}</div>;
      },
    },
    { title: "Clauses", dataIndex: "clauses", key: "clauses" },
    {
      title: "Subtype",
      dataIndex: "alert_subtype",
      key: "alert_subtype",
      render: (text, record) => (
        <>
          <div>{text}</div>
          <small style={{ fontSize: "10px", color: "#555" }}>
            Created: {formatDate(record.epoch_creation)}
            <br />
            Last edit: {formatDate(record.epoch_last_edit)}
          </small>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <span style={{ color: status === 1 ? "green" : "red", fontWeight: "bold" }}>
          {status === 1 ? "Active" : "Inactive"}
        </span>
      ),
    },
    
    {
      title: "Alert Effectiveness",
      key: "alert_effectiveness",
      width: 200,
      render: (text, record) => {
        // Fallback to use `ID` if `alert_subtype_identifier` is undefined
        const identifier = record.alert_subtype_identifier || record.ID?.toString();
    
        // console.log("Using identifier for effectiveness match:", identifier);
    
        // Find matching effectiveness data
        const effectivenessData = alertEffectiveness.find(
          (eff) => String(eff.alert_subtype_identifier) === identifier
        );
    
        if (!effectivenessData) return <span>No data available</span>;
    
        // Categorize AE fields
        const aeFields = Object.keys(effectivenessData)
          .filter((key) => key.startsWith("AE_") && effectivenessData[key] !== null)
          .reduce(
            (acc, key) => {
              const nKey = `n_${key}`;
              const value = parseFloat(effectivenessData[key]).toFixed(2);
              const count = effectivenessData[nKey] ?? "null";
              const formatted = `${key}: ${value}% (${count})`;
              if (key.includes("abs")) acc.abs.push({ key, value, formatted });
              if (key.includes("P")) acc.P.push({ key, value, formatted });
              return acc;
            },
            { abs: [], P: [] }
          );
    
        // Helper function to render fields
        const renderFields = (fields, positiveColor, negativeColor) =>
          fields.map((field) => {
            // Extract value and count from the formatted string
            const match = field.formatted.match(/([\w_]+): ([\d.-]+)% \((\d+)\)/);
        
            if (!match) {
              // Fallback if formatting doesn't match the expected structure
              return <div key={field.key}>{field.formatted}</div>;
            }
        
            const [_, key, value, count] = match; // Destructure the matched parts
        
            return (
              <div
                key={field.key}
                style={{
                  fontSize: "12px", // Adjust font size here
                  fontFamily: "Arial, sans-serif", // Adjust font family here
                }}
              >
                {key}:{" "}
                <span
                  style={{
                    color: parseFloat(value) > 0 ? positiveColor : negativeColor,
                    fontWeight: "bold", // Bold the value if needed
                  }}
                >
                  {value}%
                </span>{" "}
                ({count})
              </div>
            );
          });
        
        
    
        return (
          <Tooltip
            title={
              <div style={{ whiteSpace: "pre-wrap" }}>
                <strong>Shareprice Effectiveness</strong>
                {renderFields(aeFields.abs, "green", "red")}
                <strong>Portfolio Effectiveness</strong>
                {renderFields(aeFields.P, "red", "green")}
              </div>
            }
          >
            <div>
              <strong>Shareprice Eff</strong>
              {renderFields(aeFields.abs, "green", "red")}
              <strong>Portfolio Eff</strong>
              {renderFields(aeFields.P, "red", "green")}
            </div>
          </Tooltip>
        );
      },
    },
    
    {
      title: 'n_total',
      dataIndex: 'n_total',
      key: 'n_total',
      sorter: (a, b) => (a.n_total || 0) - (b.n_total || 0),
      render: (text) => <span>{text || '-'}</span>,
    },
    
    
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <>
          <Button type="link" onClick={() => onEditAlert(record.ID)}>
            Edit
          </Button>
          <Button type="link" danger onClick={() => onDeleteAlert(record.ID)}>
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <Collapse defaultActiveKey={["1"]} size="small">
      <Panel header={tableTitle} key="1">
        {alertsLoading ? (
          <Spin tip="Loading alerts..." />
        ) : alertsError ? (
          <Alert message={alertsError} type="error" />
        ) : (
          <Table
            columns={alertColumns}
            dataSource={enrichedAlerts}
            // dataSource={alerts}
            pagination={false}
            rowKey="ID"
            bordered
            width={1500}
          />
        )}
      </Panel>
    </Collapse>
  );
};

export default AlertTable;

