import React, { useState, useEffect } from 'react';
import { Modal, Table, message } from 'antd';
import api from '../functions/api';
import epochToDate from '../functions/epochdate';
import { fetchDetailedAlertRecords, fetchAlerts, fetchAlertEffectiveness } from "../functions/alertClauseService";
import AlertView from './AlertView'; // Import the new AlertView component


const AlertEffectiveness = ({ isVisible, onClose }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailedData, setDetailedData] = useState([]);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [detailModalTitle, setDetailModalTitle] = useState('');
  const [clickedColumnName, setClickedColumnName] = useState('');
  const [alertData, setAlertData] = useState(null); // Data for the AlertView modal
  const [isAlertModalVisible, setIsAlertModalVisible] = useState(false);
  const [alerts, setAlerts] = useState([]); 

  const fetchAllAlerts = async () => {
    setLoading(true);
    try {
      const allAlerts = await fetchAlerts();
      console.log("Fetched all alerts:", allAlerts);
      setAlerts(allAlerts); // Store all alerts data
    } catch (error) {
      console.error("Error fetching all alerts:", error);
      message.error("Failed to fetch alerts.");
    } finally {
      setLoading(false);
    }
  };

  const enrichEffectivenessData = (effectivenessData, alerts) => {
    return effectivenessData.map((record) => {
      const identifiers = record.alert_subtype_identifier
        ? record.alert_subtype_identifier.split(",").map((id) => id.trim())
        : [];
      const matchingStatuses = identifiers.map((id) => {
        const alert = alerts.find((alert) => String(alert.ID) === id);
        return alert ? alert.status : null;
      });
      const finalStatus = matchingStatuses.every((status) => status === 1) ? 1 : 0; // Active only if all are 1
      return {
        ...record,
        status: finalStatus,
      };
    });
  };
  
  
  const loadAlertEffectiveness = async () => {
    setLoading(true);
    try {
      const effectivenessData = await fetchAlertEffectiveness();
      console.log("Fetched alert effectiveness data:", effectivenessData);
      
      // Fetch alerts and enrich effectiveness data with status
      const allAlerts = await fetchAlerts();
      const enrichedData = enrichEffectivenessData(effectivenessData, allAlerts);
      
      setData(enrichedData); // Set enriched data with status
    } catch (error) {
      console.error("Error fetching alert effectiveness data:", error);
      message.error("Failed to load alert effectiveness data.");
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    if (isVisible) {
      fetchAllAlerts(); // Fetch all alerts
      loadAlertEffectiveness(); // Fetch alert effectiveness data
    }
  }, [isVisible]);

  // Handle Identifier click
  const handleIdentifierClick = (identifier) => {
    const alert = alerts.find((alert) => alert.alert_subtype_identifier === identifier);
    if (alert) {
      setAlertData(alert); // Set alert data for AlertView
      setIsAlertModalVisible(true); // Show the AlertView modal
    } else {
      message.error(`Alert with identifier ${identifier} not found.`);
    }
  };
  
  // Helper function to round numbers to 2 decimal places
  const roundToTwoDecimals = (num) => {
    if (num === null || num === undefined) return null;
    return parseFloat(num).toFixed(2);
  };

  const fetchRecordDetails = async (record, targetColumn, columnName) => {
    setLoading(true);
    try {
      const details = await fetchDetailedAlertRecords(
        record.userid,
        record.alert_subtype_identifier,
        record.alert_type,
        record.alert_subtype,
        targetColumn
      );
      // console.log(`Detailed records for ${targetColumn}:`, details);
      message.success(`Detailed records for ${targetColumn} fetched successfully!`);
  
      // Set clicked column name and modal title
      setClickedColumnName(columnName);
      setDetailModalTitle(
        `Detailed Records for ${record.alert_subtype_identifier}, ${record.alert_type}, ${record.alert_subtype}, user ${record.userid}`
      );
  
      // Show detailed records in the modal
      setDetailedData(details.map(item => ({
        ...item,
        target_column: item[targetColumn] || '-', // Add the target column dynamically
      })));
      setIsDetailModalVisible(true); // Open the detail modal
    } catch (error) {
      console.error('Error fetching detailed records:', error);
      message.error('Failed to fetch detailed records. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const fetchAlertDetails = async (identifier) => {
    setLoading(true);
    try {
      const alert = await fetchAlerts(identifier);
      console.log(`Alert details for ID ${identifier}:`, alert);
      setAlertData(alert);
      setIsAlertModalVisible(true);
    } catch (error) {
      console.error(`Error fetching alert with ID ${identifier}:`, error);
      message.error('Failed to fetch alert details. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  

    // Define the detailed modal table columns
    const detailColumns = [
      { title: 'ID', dataIndex: 'ID', key: 'ID' },
      {
        title: "Identifier",
        dataIndex: "alert_subtype_identifier",
        key: "alert_subtype_identifier",
        render: (text) => (
          <span
            style={{ color: "blue", cursor: "pointer", textDecoration: "underline" }}
            onClick={() => handleIdentifierClick(text)} // Call the handler on click
          >
            {text}
          </span>
        ),
      },
      { title: 'Alert Type', dataIndex: 'alert_type', key: 'alert_type' },
      { title: 'Alert Subtype', dataIndex: 'alert_subtype', key: 'alert_subtype' },
      {
        title: clickedColumnName,
        dataIndex: 'target_column',
        key: 'target_column',
        render: (text, record) => {
          let color = 'inherit'; // Default color
      
          // Apply coloring logic based on the clicked column
          if (clickedColumnName.includes('AE against P')) {
            // Logic for "AE against P" columns (red if positive, green if negative)
            color = text > 0 ? 'red' : text < 0 ? 'green' : 'inherit';
          } else if (clickedColumnName.includes('Absolute AE')) {
            // Logic for "Absolute AE" columns (green if positive, red if negative)
            color = text > 0 ? 'green' : text < 0 ? 'red' : 'inherit';
          }
      
          return (
            <span style={{ color }}>
              {text !== null && text !== undefined ? roundToTwoDecimals(text) : '-'}
            </span>
          );
        },
      },
      
      
      // { title: clickedColumnName, dataIndex: 'target_column', key: 'target_column' }, // Use clicked column name
      { title: 'Ticker', dataIndex: 'ticker', key: 'ticker' },
      {
        title: 'Price at Initiation',
        dataIndex: 'price_at_initiation',
        key: 'price_at_initiation',
        render: (text, record) => (
          <>
            <span>{text || '-'}</span>
            <br />
            <span style={{ color: 'gray' }}>{epochToDate(record.epoch_initiation)}</span>
          </>
        ),
      },
      {
        title: 'Price Current',
        dataIndex: 'price_current',
        key: 'price_current',
        render: (text, record) => (
          <>
            <span>{text || '-'}</span>
            <br />
            <span style={{ color: 'gray' }}>{epochToDate(record.epoch)}</span>
          </>
        ),
      },
      {
        title: 'Alert Text',
        dataIndex: 'pf_alert_txt',
        key: 'pf_alert_txt',
        render: (text) => (
          <span style={{ whiteSpace: 'pre-wrap', fontSize: '10px' }}>{text || '-'}</span>
        ),
      },
      
    ];
  
  
  
  

  // Define table columns
  const columns = [
    { title: 'ID', dataIndex: 'ID', key: 'ID', sorter: (a, b) => a.ID - b.ID },
    {
      title: 'Identifier',
      dataIndex: 'alert_subtype_identifier',
      key: 'alert_subtype_identifier',
      render: (text) => (
        <span
          style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => fetchAlertDetails(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
      sorter: (a, b) => a.alert_type.localeCompare(b.alert_type),
    },
    {
    title: 'Alert Subtype',
    dataIndex: 'alert_subtype',
    key: 'alert_subtype',
    width: 200,
    sorter: (a, b) => a.alert_subtype.localeCompare(b.alert_subtype),
    render: (text, record) => (
      <div>
        <span>{text}</span>
        <br />
        <span style={{ fontStyle: 'italic', fontSize: '12px', color: 'gray' }}>
          Alerts issued: {record.n_total || '-'}
        </span>
      </div>
    ),
  },
    {
      title: 'Absolute AE 1 Wk Avg',
      dataIndex: 'AE_AVG_abs_1w',
      key: 'AE_AVG_abs_1w',
      sorter: (a, b) => parseFloat(a.AE_AVG_abs_1w || 0) - parseFloat(b.AE_AVG_abs_1w || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'green' : text < 0 ? 'red' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_1w_absolute_return', 'Absolute AE 1 Wk Avg')}
          >
            ({record.n_AE_AVG_abs_1w || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'Absolute AE 1m Avg',
      dataIndex: 'AE_AVG_abs_1m',
      key: 'AE_AVG_abs_1m',
      sorter: (a, b) => parseFloat(a.AE_AVG_abs_1m || 0) - parseFloat(b.AE_AVG_abs_1m || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'green' : text < 0 ? 'red' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_1m_absolute_return', 'Absolute AE 1m Avg')}
          >
            ({record.n_AE_AVG_abs_1m || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'Absolute AE 6m Avg',
      dataIndex: 'AE_AVG_abs_6m',
      key: 'AE_AVG_abs_6m',
      sorter: (a, b) => parseFloat(a.AE_AVG_abs_6m || 0) - parseFloat(b.AE_AVG_abs_6m || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'green' : text < 0 ? 'red' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_6m_absolute_return', 'Absolute AE 6m Avg')}
          >
            ({record.n_AE_AVG_abs_6m || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'Absolute AE 1y Avg',
      dataIndex: 'AE_AVG_abs_1y',
      key: 'AE_AVG_abs_1y',
      sorter: (a, b) => parseFloat(a.AE_AVG_abs_1y || 0) - parseFloat(b.AE_AVG_abs_1y || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'green' : text < 0 ? 'red' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_1y_absolute_return', 'Absolute AE 1y Avg')}
          >
            ({record.n_AE_AVG_abs_1y || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'AE against P 1 Wk Avg',
      dataIndex: 'AE_AVG_P_1w',
      key: 'AE_AVG_P_1w',
      sorter: (a, b) => parseFloat(a.AE_AVG_P_1w || 0) - parseFloat(b.AE_AVG_P_1w || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'red' : text < 0 ? 'green' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_P_1w', 'AE against P 1 Wk Avg')}
          >
            ({record.n_AE_AVG_P_1w || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'AE against P 1m Avg',
      dataIndex: 'AE_AVG_P_1m',
      key: 'AE_AVG_P_1m',
      sorter: (a, b) => parseFloat(a.AE_AVG_P_1m || 0) - parseFloat(b.AE_AVG_P_1m || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'red' : text < 0 ? 'green' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_P_1m', 'AE against P 1m Avg')}
          >
            ({record.n_AE_AVG_P_1m || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'AE against P 6m Avg',
      dataIndex: 'AE_AVG_P_6m',
      key: 'AE_AVG_P_6m',
      sorter: (a, b) => parseFloat(a.AE_AVG_P_6m || 0) - parseFloat(b.AE_AVG_P_6m || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'red' : text < 0 ? 'green' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_P_6m', 'AE against P 6m Avg')}
          >
            ({record.n_AE_AVG_P_6m || '-'})
          </span>
        </>
      ),
    },
    {
      title: 'AE against P 1y Avg',
      dataIndex: 'AE_AVG_P_1y',
      key: 'AE_AVG_P_1y',
      sorter: (a, b) => parseFloat(a.AE_AVG_P_1y || 0) - parseFloat(b.AE_AVG_P_1y || 0),
      render: (text, record) => (
        <>
          <span style={{ color: text > 0 ? 'red' : text < 0 ? 'green' : 'inherit' }}>
            {roundToTwoDecimals(text) || '-'}
          </span>
          <br />
          <span
            style={{ color: 'gray', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => fetchRecordDetails(record, 'AE_P_1y', 'AE against P 1y Avg')}
          >
            ({record.n_AE_AVG_P_1y || '-'})
          </span>
        </>
      ),
    },
    {
      title: "Creation/Edit Dates",
      key: "creation_edit_dates",
      render: (_, record) => {
        const matchedAlert = alerts.find((alert) => alert.ID.toString() === record.alert_subtype_identifier);
        if (matchedAlert) {
          const created = epochToDate(matchedAlert.epoch_creation).split(" ")[0];
          const edited = epochToDate(matchedAlert.epoch_last_edit).split(" ")[0];
          return (
            <div style={{ fontSize: "8px" }}>
              <div>Created: {created}</div>
              <div>Edited: {edited}</div>
            </div>
          );
        }
        return "-";
      },
    },
    {
      title: 'n_total',
      dataIndex: 'n_total',
      key: 'n_total',
      sorter: (a, b) => (a.n_total || 0) - (b.n_total || 0),
      render: (text) => <span>{text || '-'}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        // Ensure proper mapping of status to Active/Inactive
        return (
          <span style={{ color: status === 1 ? "green" : "red", fontWeight: "bold" }}>
            {status === 1 ? "Active" : "Inactive"}
          </span>
        );
      },
    },
    
    
    
  ];
  
  return (
    <>
      <Modal
        title="Alert Effectiveness"
        visible={isVisible}
        onCancel={onClose}
        footer={null}
        width={1500}
      >
        <Table
          dataSource={data}
          columns={columns}
          loading={loading}
          rowKey="ID"
          pagination={{ pageSize: 10 }}
        />
      </Modal>
      <Modal
        title={detailModalTitle} // Dynamically set the modal title
        visible={isDetailModalVisible}
        onCancel={() => setIsDetailModalVisible(false)}
        footer={null}
        width={1200}
      >
        <Table
          dataSource={detailedData}
          columns={detailColumns}
          rowKey="ID"
          pagination={{ pageSize: 10 }}
        />
      </Modal>
      <AlertView
        isVisible={isAlertModalVisible}
        onClose={() => setIsAlertModalVisible(false)}
        alertData={alertData}
      />
    </>
  );
};

export default AlertEffectiveness;
