import React from 'react';
import { Modal, Descriptions } from 'antd';
import epochToDate from '../functions/epochdate';

const AlertView = ({ isVisible, onClose, alertData }) => {
  if (!alertData) return null;

  // console.log("alertData in AlertView", alertData);

  return (
    <Modal
      title="Alert Details"
      visible={isVisible}
      onCancel={onClose}
      footer={null}
      width={800}
    >
      <Descriptions bordered column={1}>
        <Descriptions.Item label="ID">{alertData.ID}</Descriptions.Item>
        <Descriptions.Item label="Type">{alertData.alert_type}</Descriptions.Item>
        <Descriptions.Item label="Subtype">{alertData.alert_subtype}</Descriptions.Item>
        <Descriptions.Item label="Scope">{alertData.scope}</Descriptions.Item>
        <Descriptions.Item label="Clauses">{alertData.clauses || '-'}</Descriptions.Item>
        <Descriptions.Item label="Advice">{alertData.advice_txt}</Descriptions.Item>
        <Descriptions.Item label="Status">{alertData.status === 1 ? 'Active' : 'Inactive'}</Descriptions.Item>
        <Descriptions.Item label="Created on">{epochToDate(alertData.epoch_creation)}</Descriptions.Item>
        <Descriptions.Item label="Last Edit on">{epochToDate(alertData.epoch_last_edit)}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};

export default AlertView;

