//User.jsx

import React from 'react';
import { Modal, Form, Input, Button, Tabs } from 'antd';
import api from '../functions/api';
import { useDispatch } from 'react-redux';  
import { logIn, logOut } from '../redux/actions';  

const User = ({ setUserLoggedIn, defaultTab = "2", visible, setVisible, refetchUserDetails, setToken }) => {
  const dispatch = useDispatch(); 

  const token = localStorage.getItem('token');
  const { TabPane } = Tabs;
  const [activeTab, setActiveTab] = React.useState(defaultTab); 
  const [loading, setLoading] = React.useState(false); // Add loading state for spinner

  const handleCancel = () => {
    setVisible(false); // Notify the parent component to hide the modal
  };

  const handleRegisterSubmit = async (values) => {
    try {
      await api.post('/register', values); 
      setVisible(false);
    } catch (error) {
      if (error.response) {
          console.error('Error registering user:', error.response.data);
          alert(error.response.data.error); // Display the error message to the user
      } else if (error.request) {
          console.error('No response received:', error.request);
      } else {
          console.error('Error', error.message);
      }
    }
  };
  
  const handleLoginSubmit = async (values) => {
    setLoading(true); // Start spinner
    try {
      const response = await api.post('/login', values);
      const newToken = response.data.access_token;
      localStorage.setItem('token', newToken);
      const userData = { ID: response.data.user_id };

      setToken(newToken);
      dispatch(logIn(userData));
      setTimeout(() => {
        setLoading(false); // Stop spinner
        setVisible(false); // Hide the modal
      }, 500);
    } catch (error) {
      setLoading(false); // Stop spinner on error
      if (error.response) {
        console.error('Error logging in:', error.response.data);
        alert(error.response.data.error);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error', error.message);
      }
    }
  };

  React.useEffect(() => {
    setActiveTab(defaultTab);  // Update activeTab when defaultTab changes
  }, [token, visible, defaultTab]);

  return (
    <div>
      <Modal
        title="Register/Login"
        open={visible}
        onCancel={handleCancel}
        footer={null}
      >
        <Tabs activeKey={activeTab} onChange={setActiveTab}>
          <TabPane tab="Register" key="1">
            <Form onFinish={handleRegisterSubmit}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  { type: 'email', message: 'The input is not valid E-mail!' },
                  { required: true, message: 'Please input your E-mail!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="userid"
                label="User ID"
                rules={[
                  { required: true, message: 'Please input your User ID!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                  { required: true, message: 'Please input your First Name!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                  { required: true, message: 'Please input your Last Name!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
          </TabPane>

          <TabPane tab="Login" key="2">
            <Form onFinish={handleLoginSubmit}>
              <Form.Item
                name="userid"
                label="User ID"
                rules={[
                  { required: true, message: 'Please input your User ID!' },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {loading ? '' : 'Login'}
                </Button>
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
};

export default User;
