import React, { useEffect, useState } from 'react';
import { Collapse, Table, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { getPortfolioData } from '../redux/reducers/portfolioReducer';

const { Panel } = Collapse;

const Benchmark_YTD = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false); // Avoid redundant loading state

  const token = localStorage.getItem('token');

  // Selectors for data
  const benchmarkPerformance = useSelector(state => state.portfolio?.data?.benchmark_performance);
  const benchmarkData = useSelector(state => state.portfolio?.parametersData?.b_dataset_json);

  // Trigger data fetch only if both are missing
  useEffect(() => {
    if (
      (!benchmarkPerformance || benchmarkPerformance.length === 0) &&
      (!benchmarkData || benchmarkData.length === 0) &&
      token
    ) {
      setIsLoading(true);
      dispatch(getPortfolioData(token))
        .finally(() => setIsLoading(false));
      console.log("Fetching portfolio data...");
    }
    
  }, [dispatch, token]);

  // Loading state
  
  // if (isLoading || !benchmarkPerformance || !benchmarkData) {
  //   return <Spin tip="Loading Benchmark YTD Performance..." style={{ display: 'block', textAlign: 'center', margin: '20px 0' }} />;
  // }

  // Combine benchmark data with performance
  const combinedData = (benchmarkData || []).map(bData => {
    const performance = benchmarkPerformance?.find(bp => bp[1] === bData.ticker);
    return {
      benchmark_name: bData.benchmark,
      YTD: performance ? performance[3] : 'N/A',
    };
  });
  

  const columns = [
    { title: 'Benchmark', dataIndex: 'benchmark_name', key: 'benchmark_name' },
    { title: 'YTD Performance', dataIndex: 'YTD', key: 'YTD', render: text => `${text}%` },
  ];

  return (
    <Collapse defaultActiveKey={[]} style={{ fontSize: '12px' }}>
      <Panel header="Benchmark YTD Performance" key="1">
        <Table
          dataSource={combinedData}
          columns={columns}
          pagination={false}
          rowKey="benchmark_name"
          size="small"
        />
      </Panel>
    </Collapse>
  );
};

export default Benchmark_YTD;
