// // MarketAlerts.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button, message } from "antd";
import AlertTable from "./AlertTable";
import AlertEditor from "./AlertEditor";
import { fetchAlerts, deleteAlert, updateAlert, fetchClauses, createAlert, fetchAlertEffectiveness } from "../functions/alertClauseService";
import useFetchDashboardStructure from "../functions/FetchDashboardStructure";

const MarketAlerts = ({ isVisible, onClose }) => {
  const [alerts, setAlerts] = useState([]);
  const [alertsLoading, setAlertsLoading] = useState(true);
  const [alertsError, setAlertsError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAlert, setSelectedAlert] = useState(null);
  const [clauses, setClauses] = useState([]);
  const [alertEffectiveness, setAlertEffectiveness] = useState([]); // Store alert effectiveness data
  const { dashboardStructure, loading: dashboardLoading, error: dashboardError } = useFetchDashboardStructure();

  // const { dashboardStructure: fetchedStructure } = useFetchDashboardStructure();
  // const [dashboardStructure, setDashboardStructure] = useState(null);

  // console.log("dasboardstructure in marketalerts:", dashboardStructure)

  // Fetch alert effectiveness data
  const loadAlertEffectiveness = async () => {
    try {
      const effectivenessData = await fetchAlertEffectiveness();
      // console.log("Alert effectiveness data in Market Alerts:", effectivenessData);
      setAlertEffectiveness(effectivenessData);
    } catch (error) {
      console.error("Error fetching alert effectiveness data:", error);
      message.error("Failed to load alert effectiveness data.");
    }
  };

  // Fetch all alerts
  const loadAlerts = async () => {
    setAlertsLoading(true);
    try {
      const data = await fetchAlerts();
      // console.log("Alerts data in Market Alerts:", data);
      setAlerts(data);
      setAlertsError(null);
    } catch (error) {
      setAlertsError("Error fetching alerts");
    } finally {
      setAlertsLoading(false);
    }
  };

  useEffect(() => {
    if (isVisible) {
      loadAlerts(); // Fetch alerts when modal is visible
      loadAlertEffectiveness(); // Fetch alert effectiveness data
    }
  }, [isVisible]);

  const handleEditAlert = async (alertId) => {
    const alertToEdit = alerts.find((alert) => alert.ID === alertId);
    setSelectedAlert(alertToEdit);
    setIsEditing(true);
    try {
      const clauseData = await fetchClauses();
      setClauses(clauseData);
    } catch (error) {
      console.error("Error fetching clauses:", error);
    }
  };

  const handleDeleteAlert = (alertId) => {
    Modal.confirm({
      title: "Are you sure you want to delete this alert?",
      onOk: async () => {
        try {
          await deleteAlert(alertId);
          setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.ID !== alertId));
        } catch (error) {
          console.error("Error deleting alert:", error);
        }
      },
    });
  };

  const handleSaveAlert = async (alertData, action) => {
    try {
      if (action === "update") {
        await updateAlert(alertData);
      } else if (action === "create") {
        await createAlert(alertData);
      }
      await loadAlerts(); // Refresh alerts
      setIsEditing(false);
      setSelectedAlert(null);
    } catch (error) {
      console.error("Error saving alert:", error);
    }
  };

  const handleAddAlert = async () => {
    setSelectedAlert(null);
    setIsEditing(true);
    try {
      const clauseData = await fetchClauses();
      setClauses(clauseData);
    } catch (error) {
      console.error("Error fetching clauses:", error);
    }
  };

  return (
    <Modal
      title="Creating Market Alerts"
      visible={isVisible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onClose}>
          OK
        </Button>,
        <Button key="add" type="primary" onClick={handleAddAlert}>
          Add Alert
        </Button>,
      ]}
      width={1500}
    >
      <AlertTable
        alerts={alerts}
        alertsLoading={alertsLoading}
        alertsError={alertsError}
        tableTitle="Market Alerts"
        onEditAlert={handleEditAlert}
        onDeleteAlert={handleDeleteAlert}
        alertEffectiveness={alertEffectiveness} // Pass effectiveness data to AlertTable
      />
      <AlertEditor
        visible={isEditing}
        onClose={() => setIsEditing(false)}
        alertData={selectedAlert}
        onSave={handleSaveAlert}
        clauses={clauses}
        dashboardStructure={dashboardStructure}
      />
    </Modal>
  );
};

export default MarketAlerts;
