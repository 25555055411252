// AlertTestingResultsTable.jsx
import React from 'react';
import { Modal, Table } from 'antd';

const AlertTestingResultsTable = ({ visible, onClose, testResults, columnNames }) => {
    console.log("testResults in AlertTestingResultsTable", testResults);

    // Determine if we are working with alerts or clauses by checking the first item keys
    const isAlertData = testResults[0]?.alert_text !== undefined;

    // Map column names to their respective dataIndex based on test type (alert vs clause)
    const columns = columnNames.map((colName) => {
        // Map column name to correct data index based on test result type
        let dataIndex;
        if (colName === 'Clause text' || colName === 'Alert text') {
            dataIndex = isAlertData ? 'alert_text' : 'clause_text';
        } else if (colName === 'Ticker') {
            dataIndex = 'ticker';
        } else if (colName === 'User') {
            dataIndex = isAlertData ? 'userid' : 'user';
        }

        return {
            title: colName,
            dataIndex,
            key: dataIndex,
        };
    });

    return (
        <Modal
            title="Test Results"
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={800}
        >
            <Table
                columns={columns}
                dataSource={Array.isArray(testResults) ? testResults : []}
                pagination={{ pageSize: 10 }}
                rowKey={(record) => `${record.ticker}-${isAlertData ? record.userid : record.user}`} // Unique rowKey based on test type
            />
        </Modal>
    );
};

export default AlertTestingResultsTable;
