import React, { useState, useEffect } from 'react';
import { Button, Collapse, Table, Modal, message } from 'antd'; // Import Modal and message for confirmation
import api from '../functions/api';

const { Panel } = Collapse;

const ScriptsComponent = () => {
  const [dcScripts, setDcScripts] = useState([]);
  const [drScripts, setDrScripts] = useState([]);
  const [dsScripts, setDsScripts] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [selectedScript, setSelectedScript] = useState(null); // Track selected script
  const [isConfirmVisible, setIsConfirmVisible] = useState(false); // Control the confirmation modal

  // Function to sort the scripts alphabetically
  const sortScripts = (scripts) => {
    return scripts.sort((a, b) => a.localeCompare(b));
  };

  // Fetch scripts from the backend
  const fetchScripts = () => {
    setIsFetching(true);
    api.get('/scripts') // API call to fetch scripts
      .then((response) => {
        setDcScripts(sortScripts(response.data.dc_scripts));
        setDrScripts(sortScripts(response.data.dr_scripts));
        setDsScripts(sortScripts(response.data.ds_scripts));

        console.log("response.data.dr_scripts", response.data.dr_scripts)

      })
      .catch((error) => {
        console.error('Error fetching scripts:', error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    fetchScripts(); // Fetch scripts when the component mounts
  }, []);

  const handleScriptExecution = () => {
    // API call to execute the script
    api.post('/scripts', { script_name: selectedScript })
      .then((response) => {
        message.success(`Script "${selectedScript}" executed successfully!`);
      })
      .catch((error) => {
        message.error('Error executing script');
        console.error('Error executing script:', error);
      })
      .finally(() => {
        setIsConfirmVisible(false); // Hide the confirmation modal after execution
      });
  };

  const handleExecuteClick = (scriptName) => {
    setSelectedScript(scriptName); // Set the selected script
    setIsConfirmVisible(true); // Show the confirmation modal
  };

  const columns = [
    {
      title: 'Script Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" onClick={() => handleExecuteClick(record.name)}>
          Execute
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Collapse accordion>
        <Panel header="DC Scripts" key="1">
          <Table 
            columns={columns} 
            dataSource={dcScripts.map((script, index) => ({ key: index, name: script }))}
            loading={isFetching}
            pagination={false}
            size="small"
          />
        </Panel>
        <Panel header="DR Scripts" key="2">
          <Table 
            columns={columns} 
            dataSource={drScripts.map((script, index) => ({ key: index, name: script }))}
            loading={isFetching}
            pagination={false}
            size="small"
          />
        </Panel>
        <Panel header="DS Scripts" key="3">
          <Table 
            columns={columns} 
            dataSource={dsScripts.map((script, index) => ({ key: index, name: script }))}
            loading={isFetching}
            pagination={false}
            size="small"
          />
        </Panel>
      </Collapse>

      {/* Confirmation Modal */}
      <Modal
        title="Are you sure?"
        visible={isConfirmVisible}
        onOk={handleScriptExecution} // Execute the script if confirmed
        onCancel={() => setIsConfirmVisible(false)} // Close the modal on cancel
        okText="Yes"
        cancelText="No"
      >
        <p>Do you really want to execute the script: {selectedScript}?</p>
      </Modal>
    </div>
  );
};

export default ScriptsComponent;
