////PF_Alerts

import React, { useState } from 'react';
import { Table, Tooltip, Checkbox, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import ThreeTablesModal from "./SearchResultModal";
import getUserId from '../functions/getUserId';
import api from '../functions/api';
import epochToDate from '../functions/epochdate';
import OpportunityCheck from "./OpportunityCheck";



// Redux action to update the 'seen' status
const updateSeenStatus = (id, seen) => {
  return {
    type: 'UPDATE_SEEN_STATUS',
    payload: { id, seen },
  };
};

const PF_Alerts = () => {
  const dispatch = useDispatch();
  const [isOpportunityCheckVisible, setIsOpportunityCheckVisible] = useState(false);
  const handleReinvestmentCheck = (ticker) => {
    setSelectedTicker(ticker); // Set the selected ticker
    setIsOpportunityCheckVisible(true); // Show the OpportunityCheck component
  };

  
  const harvestAlerts = useSelector(state => state?.portfolio?.data?.pf_alert);
  // console.log("alerts in PF_Alerts", harvestAlerts)
  const alertEffectiveness = useSelector(state => state?.portfolio?.data?.alert_effectiveness_data);
  // console.log("alert Effectiveness data in PF_Alerts", alertEffectiveness)

  // Sort by seen status first, then by ticker in alphabetical order
  const sortedHarvestAlerts = Array.isArray(harvestAlerts)
  ? [...harvestAlerts].sort((a, b) => {
      if (a.seen !== b.seen) return a.seen - b.seen; // sort unseen (0) first
      return a.ticker.localeCompare(b.ticker);
    })
  : [];


  const formatAlertText = (text) => {
    if (!text) return '';
    const becauseIndex = text.indexOf('because:');
    if (becauseIndex === -1) return text;
  
    const beforeBecause = text.substring(0, becauseIndex + 8); // Includes 'because:'
    const afterBecause = text
      .substring(becauseIndex + 8)
      .replace(/ - /g, '\n- ') // Add line break before each '-'
      .replace(/\n+/g, '\n'); // Clean up multiple newlines
  
    return `${beforeBecause}\n${afterBecause.trim()}`;
  };
  
  
  

  const handleCheckboxChange = async (id, currentStatus) => {
    try {
      const newStatus = currentStatus === 1 ? 0 : 1;
      await api.post('/pf_alert_check_update', { id, seen: newStatus });
      dispatch(updateSeenStatus(id, newStatus));
    } catch (error) {
      console.error('Failed to update seen status', error);
    }
  };

  const userId = getUserId();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTicker, setSelectedTicker] = useState(null);

  const handleRowClick = (ticker) => {
    setSelectedTicker(ticker);
    setIsModalVisible(true);
  };

  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text) => (
        <Tooltip title={text}>
          <div
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => handleRowClick(text)}
          >
            {text}
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (text, record) => (
        <Tooltip title={text}>
          <div>
            <div
              style={{ cursor: 'pointer', color: 'blue' }}
              onClick={() => handleRowClick(record.ticker)}
            >
              {text.length > 20 ? `${text.substring(0, 20)}...` : text}
            </div>
            <div style={{ fontSize: '12px', color: 'gray' }}>
              Share price:
            </div>
            <div style={{ fontSize: '12px', color: 'gray' }}>
              at alert initiation: {parseFloat(record.price_at_initiation).toFixed(2)}
            </div>
            <div style={{ fontSize: '12px', color: 'gray' }}>
              current: {parseFloat(record.price_current).toFixed(2)}
            </div>
          </div>
        </Tooltip>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'Category',
      key: 'Category',
    },
    {
      title: 'Alert Type',
      dataIndex: 'alert_type',
      key: 'alert_type',
      render: (text, record) => (
        <div>
          <span>{text}</span>
          {text === 'Harvest' && (
            <Button
              size="small"
              type="primary"
              style={{ marginLeft: '10px' }}
              onClick={() => handleReinvestmentCheck(record.ticker)}
            >
              Re-investment check
            </Button>
          )}
        </div>
      ),
    },
    {
      title: 'Alert Subtype',
      dataIndex: 'alert_subtype',
      key: 'alert_subtype',
    },
    {
      title: 'Checked?',
      dataIndex: 'seen',
      key: 'seen',
      render: (text, record) => (
        <Checkbox 
          checked={record.seen === 1} 
          onChange={() => handleCheckboxChange(record.ID, record.seen)}
        />
      ),
    },
    {
      title: 'Alert Text',
      dataIndex: 'pf_alert_text',
      key: 'pf_alert_text',
      render: (text) => {
        const formattedLines = formatAlertText(text).split('\n'); // Split into lines
        return (
          <div>
            {formattedLines.map((line, index) => {
              
              if (line.startsWith('- (initiated') || line.startsWith('- (updated')) {
                // Render metadata lines
                return (
                  <div key={index} 
                  style={{ color: 'gray', fontStyle: 'italic', fontSize: '10px' }}>
                    {line.replace(/^- /, '')}
                  </div>
                );
              } else
              if (line.startsWith('-')) {
                // Render bullet points
                return (
                  <div key={index} className="bullet-container">
                    {line.replace(/^- /, '')}
                  </div>
                );
              } 

              // Default rendering for other lines
              return <div key={index}>{line}</div>;
            })}
          </div>
        );
      },
    },
    
    
    
    
    
    {
      title: 'Alert Effectiveness',
      key: 'alert_effectiveness',
      width: 200,
      render: (text, record) => {
        const effectivenessData = alertEffectiveness.find(
          (eff) => eff.alert_subtype_identifier === record.alert_subtype_identifier
        );
    
        if (!effectivenessData) {
          return <span>No data available</span>;
        }
    
        const aeFields = Object.keys(effectivenessData)
          .filter((key) => key.startsWith('AE_') && effectivenessData[key] !== null)
          .reduce(
            (acc, key) => {
              const nKey = `n_${key}`;
              const value = parseFloat(effectivenessData[key]).toFixed(2);
              const count = effectivenessData[nKey] ?? 'null';
              const formatted = `${key}: ${value}% (${count})`;
    
              if (key.includes('abs')) {
                acc.abs.push({ key, value: parseFloat(value), formatted });
              } else if (key.includes('P')) {
                acc.P.push({ key, value: parseFloat(value), formatted });
              }
    
              return acc;
            },
            { abs: [], P: [] }
          );
    
        const order = ['1w', '1m', '6m', '1y'];
        const sortedAbs = aeFields.abs.sort(
          (a, b) => order.indexOf(a.key.split('_').pop()) - order.indexOf(b.key.split('_').pop())
        );
        const sortedP = aeFields.P.sort(
          (a, b) => order.indexOf(a.key.split('_').pop()) - order.indexOf(b.key.split('_').pop())
        );
    
        const renderFields = (fields, positiveColor, negativeColor) =>
          fields.map((field) => {
            const match = field.formatted.match(/([\w_]+): ([\d.-]+)% \(([\w\d-]+)\)/); // Adjust regex to capture 'null'
            if (!match) {
              return <div key={field.key}>{field.formatted}</div>; // Fallback for unexpected formatting
            }
            const [_, key, value, count] = match; // Extract key, value, and count
        
            // Handle color logic for value
            const valueColor = parseFloat(value) > 0 ? positiveColor : parseFloat(value) < 0 ? negativeColor : 'inherit';
        
            // Style count differently if it's 'null'
            const countDisplay = count === 'null' ? (
              <span style={{ color: 'gray', fontStyle: 'italic' }}>null</span>
            ) : (
              count
            );
        
            return (
              <div
                key={field.key}
                style={{
                  fontSize: '12px',
                  fontFamily: 'Arial, sans-serif',
                }}
              >
                {key}:{' '}
                <span style={{ color: valueColor, fontWeight: 'bold' }}>{value}%</span> ({countDisplay})
              </div>
            );
          });
        
    
        const hasSharepriceData = sortedAbs.length > 0;
        const hasPortfolioData = sortedP.length > 0;
    
        if (!hasSharepriceData && !hasPortfolioData) {
          return <span>No effectiveness data yet available</span>;
        }
    
        return (
          <Tooltip
            title={
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {hasSharepriceData && (
                  <>
                    <div>
                      <strong>Shareprice Effectiveness</strong>
                    </div>
                    {renderFields(sortedAbs, 'green', 'red')}
                  </>
                )}
                {hasPortfolioData && (
                  <>
                    <div style={{ marginTop: '8px' }}>
                      <strong>Portfolio Effectiveness</strong>
                    </div>
                    {renderFields(sortedP, 'red', 'green')}
                  </>
                )}
              </div>
            }
          >
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {hasSharepriceData && (
                <>
                  <div>
                    <strong>Shareprice Eff</strong>
                  </div>
                  {renderFields(sortedAbs, 'green', 'red')}
                </>
              )}
              {hasPortfolioData && (
                <>
                  <div style={{ marginTop: '8px' }}>
                    <strong>Portfolio Eff</strong>
                  </div>
                  {renderFields(sortedP, 'red', 'green')}
                </>
              )}
            </div>
          </Tooltip>
        );
      },
    }
    
    
    
    
    
    
  ];

  return (
    <>
      <Table
        dataSource={sortedHarvestAlerts}
        columns={columns}
        rowKey="ID"
        pagination={{ pageSize: 5 }}
      />
      {isModalVisible && (
        <ThreeTablesModal 
          tickerValue={selectedTicker} 
          open={isModalVisible} 
          onClose={() => setIsModalVisible(false)} 
          userId={userId} 
        />
      )}
      {isOpportunityCheckVisible && (
          <OpportunityCheck 
            ticker={selectedTicker} 
            onClose={() => setIsOpportunityCheckVisible(false)} 
          />
        )}
    </>
  );
};

export default PF_Alerts;

